<template>
  <v-container class="dashboard p-3 px-0 px-md-3">
    <!-- Filtros -->
    <FiltroMobile :has-change="pendingSearch" @search="seachFilter">
      <v-col class="col-12 col-xs-12 col-sm-12 col-lg-4">
        <EmpresasSelect v-model="svisSelected" svi multiple mostra-todos-label return-object></EmpresasSelect>
      </v-col>

      <v-col class="col-12 col-xs-12 col-sm-12 col-lg-3">
        <ProducoesSelect :empresa="svisSelected" v-model="producoesSelected" svi multiple mostra-todos-label return-object></ProducoesSelect>
      </v-col>

      <v-col class="col-12 col-xs-12 col-sm-12 col-lg-3">
        <DatePicker v-model="dataInput" />
      </v-col>
    </FiltroMobile>

    <!-- Grupo de 2 colunas -->
    <v-row>
      <!-- Dados do Dia -->
      <v-col class="col-12 col-lg-6 mt-3">
        <v-card>
          <v-card-title class="justify-start justify-md-center">
            Dados do Dia
          </v-card-title>
          <v-card-text class="pb-4 px-0 px-md-4">
            <v-data-table
                  dense
                  disable-pagination
                  hide-default-header
                  hide-default-group-header
                  hide-default-footer
                  class="elevation-1"
                  mobile-breakpoint="0"
                  :loading="loading"
                  loading-text="Carregando"
                  :headers="dadosDoDiaHeaders"
                  :items="dadosDoDiaItems"
                >
                  <template v-slot:progress>
                    <v-progress-linear
                      absolute
                      indeterminate
                      color="green"
                    ></v-progress-linear>
                  </template>

                  <template v-slot:header={props}>
                    <tr>
                      <th :class="['p-2 px-2 px-md-4 primary', { 'text-right': head.text.toUpperCase() !== 'FAMÍLIA' }]" v-for="(head, index) in props.headers" v-bind:key="index">
                        <span class="text-white">{{ head.text.toUpperCase() }}</span>
                      </th>
                    </tr>
                  </template>

                  <template v-slot:[`item.familia`]="{ item }">
                    {{ item.familia }}
                  </template>

                  <template v-slot:[`item.agendado`]="{ item }">
                    {{ $stringFormat.formatNumber(item.agendado, 0, 4) }}
                  </template>

                  <template v-slot:[`item.carregado`]="{ item }">
                    {{ $stringFormat.formatNumber(item.carregado, 0, 4) }}
                  </template>

                  <template v-slot:[`item.vrUnit`]="{ item }">
                    {{ $stringFormat.formatNumber(item.vrUnit) }}
                  </template>

                  <template v-slot:[`item.totalDoDia`]="{ item }">
                    {{ $stringFormat.formatNumber(item.totalDoDia, 0, 0) }}
                  </template>

                  <template v-slot:[`body.append`]="">
                    <tr class="v-row-group__summary">
                      <th>TOTAL</th>
                      <th class="text-right" v-for="(sum, index) in totalSomadoDadosDoDia('todos')" :key="`total-dados-dia-${index}`">{{sum}}</th>
                    </tr>
                  </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

       <!-- Acumulados do Mes -->
      <v-col class="col-12 col-lg-6 mt-3">
        <v-card>
          <v-card-title class="justify-start justify-md-center">
            Acumulado do Mês
          </v-card-title>
          <v-card-text class="pb-4 px-0 px-md-4">
            <v-data-table
                  dense
                  disable-pagination
                  hide-default-header
                  hide-default-group-header
                  hide-default-footer
                  class="elevation-1"
                  mobile-breakpoint="0"
                  :loading="loading"
                  loading-text="Carregando"
                  :headers="acumuladosDoMesHeaders"
                  :items="acumuladosDoMesItems"
                >
                  <template v-slot:progress>
                    <v-progress-linear
                      absolute
                      indeterminate
                      color="green"
                    ></v-progress-linear>
                  </template>

                  <template v-slot:header={props}>
                    <th :class="['p-2 px-2 px-lg-4 primary', { 'text-right': head.text.toUpperCase() !== 'FAMÍLIA' }]" v-for="(head, index) in props.headers" v-bind:key="index">
                      <span class="text-white">{{ head.text.toUpperCase() }}</span>
                    </th>
                  </template>

                  <template v-slot:[`item.familia`]="{ item }">
                    {{ item.familia }}
                  </template>

                  <template v-slot:[`item.qtdeMensal`]="{ item }">
                    {{ $stringFormat.formatNumber(item.qtdeMensal, 0, 4) }}
                  </template>

                  <template v-slot:[`item.vrUnitMensal`]="{ item }">
                    {{ $stringFormat.formatNumber(item.vrUnitMensal) }}
                  </template>

                  <template v-slot:[`item.totalMensal`]="{ item }">
                    {{ $stringFormat.formatNumber(item.totalMensal, 0, 0) }}
                  </template>

                  <template v-slot:[`body.append`]="">
                    <tr class="v-row-group__summary">
                      <th>TOTAL</th>
                      <th class="text-right" v-for="(sum, index) in totalSomadoAcumuladoDoMes('todos')" :key="`total-acumulado-mes-${index}`">{{sum}}</th>
                    </tr>
                  </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Gráficos -->
    <v-card class="p-3 my-3 d-none d-md-block mt-3" v-if="dashboardData.length !== 0">
      <v-card-text>
        <v-row>
          <v-col class="col-12 col-lg-6">
            <v-row>
              <v-col class="col-12">
                <div>
                  <ApexChart v-if="groupTypeChartRetiradasAno === 'mes'" type="line" height="350" :options="retiradasDoAnoChartOptions" :series="retiradasDoAno" />
                  <ApexChart v-if="groupTypeChartRetiradasAno === 'familia'" type="line" height="350" :options="retiradasDoAnoPorFamiliaChartOptions" :series="retiradasDoAnoPorFamilia" />
                </div>
              </v-col>
              <v-col class="col-12 text-center p-0">
                <v-btn depressed small v-on:click="toggleChartRetiradasAno('mes')" :class="['mr-5', {'primary': groupTypeChartRetiradasAno === 'mes' }]">Por Mês</v-btn>
                <v-btn depressed small v-on:click="toggleChartRetiradasAno('familia')" :class="[{'primary': groupTypeChartRetiradasAno === 'familia' }]">Por Família</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-12 col-lg-6">
            <v-row>
              <v-col class="col-12">
                <div>
                  <ApexChart v-if="groupTypeChartRetiradasMes === 'dia'" type="line" height="350" :options="retiradasDoMesChartOptions" :series="retiradasDoMes"></ApexChart>
                  <ApexChart v-if="groupTypeChartRetiradasMes === 'semana'" type="bar" height="350" :options="retiradasDoMesPorSemanaChartOptions" :series="retiradasDoMesPorSemana"></ApexChart>
                  <ApexChart v-if="groupTypeChartRetiradasMes === 'familia'" type="line" height="350" :options="retiradasDoMesPorFamiliaChartOptions" :series="retiradasDoMesPorFamilia"></ApexChart>
                </div>
              </v-col>
              <v-col class="col-12 text-center p-0">
                <v-btn depressed small v-on:click="toggleChartRetiradasMes('dia')" :class="['mr-5', {'primary': groupTypeChartRetiradasMes === 'dia' }]">Por Dia</v-btn>
                <v-btn depressed small v-on:click="toggleChartRetiradasMes('semana')" :class="['mr-5', {'primary': groupTypeChartRetiradasMes === 'semana' }]">Por Semana</v-btn>
                <v-btn depressed small v-on:click="toggleChartRetiradasMes('familia')" :class="[{'primary': groupTypeChartRetiradasMes === 'familia' }]">Por Família</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Carregamentos do Mês -->
    <v-row>
      <v-col>
        <v-card class="mt-3">
          <v-card-title class="justify-start justify-md-center">
            Carregamentos do Mês
          </v-card-title>
          <v-card-text class="p-4 px-0 px-md-4">
            <v-data-table
                  dense
                  disable-pagination
                  hide-default-header
                  hide-default-group-header
                  hide-default-footer
                  class="elevation-1"
                  mobile-breakpoint="0"
                  group-by="familia"
                  group-desc
                  loading-text="Carregando"
                  :loading="loading"
                  :headers="carregamentosDoMesHeaders"
                  :items="carregamentosDoMesItems"
                >
                  <template v-slot:progress>
                    <v-progress-linear
                      absolute
                      indeterminate
                      color="green"
                    ></v-progress-linear>
                  </template>

                  <template v-slot:header={props}>
                    <th :class="['p-2 px-2 px-md-4 primary', { 'text-right': head.text.toUpperCase() !== 'PRODUÇÃO' }]" v-for="(head, index) in props.headers" v-bind:key="index"><span class="text-white">{{ head.text.toUpperCase() }}</span></th>
                  </template>

                  <template v-slot:[`item.producao`]="{ item }">
                    <router-link
                      :to="{ path: '/vendas/faturamento/carregamentos-concluidos', query: { empresa_id: item.empresa_id, producao_id: item.producao_id, data_carregamento: dataInput  }}"
                    >
                        {{ item.producao }}
                    </router-link>
                  </template>

                  <template v-slot:[`item.qtdeRealizado`]="{ item }">
                    {{ $stringFormat.formatNumber(item.qtdeRealizado, 0, 4) }}
                  </template>

                  <template v-slot:[`item.valorUnitRealizado`]="{ item }">
                    {{ $stringFormat.formatNumber(item.valorUnitRealizado) }}
                  </template>

                  <template v-slot:[`item.valorRealizado`]="{ item }">
                    {{ $stringFormat.formatNumber(item.valorRealizado, 0, 0) }}
                  </template>

                  <template v-slot:[`item.qtdeProjetado`]="{ item }">
                    {{ $stringFormat.formatNumber(item.qtdeProjetado, 0, 0) }}
                  </template>

                  <template v-slot:[`item.valorUnitProjetado`]="{ item }">
                    {{ $stringFormat.formatNumber(item.valorUnitProjetado) }}
                  </template>

                  <template v-slot:[`item.valorTotalProjetado`]="{ item }">
                    {{ $stringFormat.formatNumber(item.valorTotalProjetado, 0, 0) }}
                  </template>

                  <template v-slot:[`group.header`]="{group, items, isOpen, toggle}">
                    <th>
                      <v-icon @click="toggle"
                        >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                      </v-icon>
                      {{ group }} ({{items.length}})
                    </th>
                    <th v-for="(header, index) in carregamentosDoMesSubHeaders" :key="`carregamentos-mes-subcoluna-${group}-${index}`">
                      {{ header.text }}
                    </th>
                  </template>
                  <template v-slot:[`group.summary`]="{group}">
                      <th class="bg-white">TOTAL DE {{group}}</th>
                      <th class="bg-white text-right" v-for="(sum, index) in totalSomadoCarregamentosDoMes(group)" :key="`total-grupo-${group}-${index}`">{{sum}}</th>
                  </template>

                  <template v-slot:[`body.append`]="">
                    <tr class="v-row-group__summary">
                      <th>TOTAL GERAL</th>
                      <th class="text-right" v-for="(sum, index) in totalSomadoCarregamentosDoMes('todos')" :key="`total-geral-${index}`">{{sum}}</th>
                    </tr>
                  </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Grupo de 2 colunas -->
    <!-- Agenda do Dia -->
    <v-row>
      <v-col class="col-sm-12 col-lg-6 mt-3">
        <v-card>
          <v-card-title class="justify-start justify-md-center">
            Agenda do Dia
          </v-card-title>
          <v-card-text class="p-4 px-0 px-md-4">
            <v-data-table
              dense
              disable-pagination
              hide-default-header
              hide-default-footer
              class="elevation-1"
              mobile-breakpoint="0"
              loading-text="Carregando"
              group-by="familia"
              group-desc
              :loading="loading"
              :headers="agendaDoDiaHeaders"
              :items="agendaDoDiaItems"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  indeterminate
                  color="green"
                ></v-progress-linear>
              </template>

              <template v-slot:header={props}>
                <th :class="['p-2 px-2 px-md-4 primary', { 'text-right': head.text.toUpperCase() !== 'FILIAL' ,'text-start': head.text.toUpperCase() == 'STATUS' }]" v-for="(head, index) in props.headers" v-bind:key="index">
                  <span class="text-white">{{ head.text.toUpperCase() }}</span>
                </th>
              </template>

              <template v-slot:[`group.header`]="{group, items, isOpen, toggle}">
                <th colspan="4">
                  <v-icon @click="toggle"
                    >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                  </v-icon>
                  {{ group }} ({{items.length}})
                </th>
                <th v-for="(header, index) in agendaDoDiaSubHeaders" :key="`agenda-do-dia-subcoluna-${group}-${index}`">
                  {{ header.text }}
                </th>
              </template>

              <template v-slot:[`item.producao`]="{ item }">
                <router-link
                  :to="{ path: '/vendas/faturamento/agenda-carregamento', query: { empresa_id: item.empresa_id, producao_id: item.producao_id, data_carregamento: dataInput  }}"
                >
                    {{ item.producao }}
                </router-link>
              </template>

              <template v-slot:[`item.status`]="{item}">
               <custom-label
                :class="situacaoProducaoClass(item.empresa_id, item.producao_id, dataInput)"
                :text="situacaoProducaoNome(item.empresa_id, item.producao_id, dataInput)"
               />
              </template>

              <template v-slot:[`item.agendado`]="{ item }">
                {{ item.agendado | formatNumber(0, 0) }}
              </template>

              <template v-slot:[`item.carregado`]="{ item }">
                {{ item.carregado | formatNumber(0, 0) }}
              </template>

              <template v-slot:[`item.valor_frete`]="{ item }">
                {{ item.valor_frete | formatNumber(0, 0) }}
              </template>

              <template v-slot:[`item.valor_total`]="{ item }">
                {{ item.valor_total | formatNumber(0, 0) }}
              </template>

              <template v-slot:[`group.summary`]="{group}">
                <th class="bg-white" colspan="2">TOTAL DE {{group}}</th>
                <th class="bg-white text-right" v-for="(sum, index) in totalSomadoAgendaDoDia(group)" :key="`total-grupo-${group}-${index}`">{{sum}}</th>
              </template>

              <template v-slot:[`body.append`]="">
                <tr class="v-row-group__summary">
                  <th colspan="2">TOTAL GERAL</th>
                  <th class="text-right" v-for="(sum, index) in totalSomadoAgendaDoDia('todos')" :key="`total-agenda-dia-${index}`">{{sum}}</th>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Variedades Dia e Mês -->
      <v-col class="col-sm-12 col-lg-6 mt-3">
        <!-- Agenda do Dia por Variedades -->
        <v-card class="mb-3">
          <v-card-title class="justify-start justify-md-center">
            Agenda do Dia por Variedades
          </v-card-title>
          <v-card-text class="p-4 px-0 px-md-4">
            <v-data-table
              dense
              disable-pagination
              hide-default-header
              hide-default-footer
              class="elevation-1"
              mobile-breakpoint="0"
              loading-text="Carregando"
              group-by="familia"
              group-desc
              :loading="loading"
              :headers="agendaDoDiaPorVariedadesHeaders"
              :items="agendaDoDiaPorVariedadesItems"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  indeterminate
                  color="green"
                ></v-progress-linear>
              </template>

              <template v-slot:header={props}>
                <th :class="['p-2 px-2 px-md-4 primary', { 'text-right': head.text.toUpperCase() !== 'VARIEDADE' }]" v-for="(head, index) in props.headers" v-bind:key="index">
                  <span class="text-white">{{ head.text.toUpperCase() }}</span>
                </th>
              </template>

              <template v-slot:[`group.header`]="{group, items, isOpen, toggle}">
                <th>
                  <v-icon @click="toggle"
                    >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                  </v-icon>
                  {{ group }} ({{items.length}})
                </th>
                <th v-for="(header, index) in agendaDoDiaPorVariedadesSubHeaders" :key="`agenda-do-dia-subcoluna-${group}-${index}`">
                  {{ header.text }}
                </th>
              </template>

              <template v-slot:[`item.agendado`]="{ item }">
                {{ $stringFormat.formatNumber(item.agendado, 0, 4) }}
              </template>

              <template v-slot:[`item.carregado`]="{ item }">
                {{ $stringFormat.formatNumber(item.carregado, 0, 4) }}
              </template>

              <template v-slot:[`group.summary`]="{group}">
                <th class="bg-white">TOTAL DE {{group}}</th>
                <th class="bg-white text-right" v-for="(sum, index) in totalSomadoAgendaDoDiaPorVariedades(group)" :key="`total-agenda-dia-${group}-${index}`">{{sum}}</th>
              </template>

              <template v-slot:[`body.append`]="">
                <tr class="v-row-group__summary">
                  <th>TOTAL GERAL</th>
                  <th class="text-right" v-for="(sum, index) in totalSomadoAgendaDoDiaPorVariedades('todos')" :key="`total-agenda-dia-${index}`">{{sum}}</th>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <!-- Carregamentos do Mês por Variedades -->
        <v-card>
          <v-card-title class="justify-start justify-md-center">
            Carregamentos do Mês por Variedades
          </v-card-title>
          <v-card-text class="p-4 px-0 px-md-4">
            <v-data-table
              dense
              disable-pagination
              hide-default-header
              hide-default-footer
              class="elevation-1"
              mobile-breakpoint="0"
              loading-text="Carregando"
              group-by="familia"
              group-desc
              :loading="loading"
              :headers="carregamentosDoMesPorVariedadesHeaders"
              :items="carregamentosDoMesPorVariedadesItems"
            >
              <template v-slot:progress>
                <v-progress-linear
                  absolute
                  indeterminate
                  color="green"
                ></v-progress-linear>
              </template>

              <template v-slot:header={props}>
                <th :class="['p-2 px-2 px-md-4 primary', { 'text-right': head.text.toUpperCase() !== 'VARIEDADE' }]" v-for="(head, index) in props.headers" v-bind:key="index">
                  <span class="text-white">{{ head.text.toUpperCase() }}</span>
                </th>
              </template>

              <template v-slot:[`group.header`]="{group, items, isOpen, toggle}">
                <th>
                  <v-icon @click="toggle"
                    >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                  </v-icon>
                  {{ group }} ({{items.length}})
                </th>
                <th v-for="(header, index) in carregamentosDoMesPorVariedadesSubHeaders" :key="`agenda-do-dia-subcoluna-${group}-${index}`">
                  {{ header.text }}
                </th>
              </template>

              <template v-slot:[`item.quantidade`]="{ item }">
                {{ $stringFormat.formatNumber(item.quantidade, 0, 4) }}
              </template>

              <template v-slot:[`item.vrUnit`]="{ item }">
                {{ $stringFormat.formatNumber(item.vrUnit) }}
              </template>

              <template v-slot:[`item.vrTotal`]="{ item }">
                {{ $stringFormat.formatNumber(item.vrTotal, 0, 0) }}
              </template>

              <template v-slot:[`group.summary`]="{group}">
                <th class="bg-white">TOTAL DE {{group}}</th>
                <th class="bg-white text-right" v-for="(sum, index) in totalSomadoCarregamentosDoMesPorVariedadesItems(group)" :key="`total-mes-variedades-${group}-${index}`">{{sum}}</th>
              </template>

              <template v-slot:[`body.append`]="">
                <tr class="v-row-group__summary">
                  <th>TOTAL GERAL</th>
                  <th class="text-right" v-for="(sum, index) in totalSomadoCarregamentosDoMesPorVariedadesItems('todos')" :key="`total-mes-variedades-${index}`">{{sum}}</th>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.col-btn {
  max-width: max-content;
}

@media (min-width: 991.98px)  {
  .dashboard .row td, .row th {
    font-size: 1.05rem !important;
  }
}

a:hover {
  font-weight: bold;
  text-decoration: underline !important;
}
</style>

<script>
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'

import ApiService from '@/core/services/api.service'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { CLOSE_FILTER } from '@/core/services/store/filter.module'
import { UPDATE_SITUACAO_PRODUCAO } from '@/core/services/store/producao.status.module'

import _ from "lodash";

export default {
  name: 'Dashboard',
  components: {
    ApexChart: VueApexCharts,
  },
  data: function () {
    return {
      dashboardData: [],
      faturamentoData: [],

      pendingSearch: false,
      svisSelected: [''],
      producoesSelected: [''],
      dataInput: this.$date.getISOToday(),
      dataMenuInput: false,
      chartNoData: 'Sem dados para exibir',
      groupTypeChartRetiradasAno: 'mes',
      groupTypeChartRetiradasMes: 'semana',

      loading: false,

      dadosDoDiaHeaders: [
        { text: 'Família', value: 'familia' },
        { text: 'Agendado (m²)', value: 'agendado', align: 'right' },
        { text: 'Carregado (m²)', value: 'carregado', align: 'right' },
        { text: 'Vr Unit (R$)', value: 'vrUnit', align: 'right' },
        { text: 'Total do Dia (R$)', value: 'totalDoDia', align: 'right' },
      ],

      acumuladosDoMesHeaders: [
        { text: 'Família', value: 'familia' },
        { text: 'Qtde Mensal (m²)', value: 'qtdeMensal', align: 'right' },
        { text: 'Vr Unit Mensal (R$)', value: 'vrUnitMensal', align: 'right' },
        { text: 'Total Mensal (R$)', value: 'totalMensal', align: 'right' },
      ],

      // Carregamentos do Mês
      carregamentosDoMesHeaders: [
        { text: '', value: 'familia' },
        { text: 'Produção', value: 'producao', width: '30%' },
        // { text: 'Dias Carregados', value: 'diasCarregados' },
        { text: 'Qtde Realizado (m²)', value: 'qtdeRealizado', align: 'right' },
        { text: 'Vr. Unit. Realizado (R$)', value: 'valorUnitRealizado', align: 'right' },
        { text: 'Vr. Realizado (R$)', value: 'valorRealizado', align: 'right' },
        // { text: 'Dias Úteis', value: 'diasUteis' },
        { text: 'Qtde Projetado', value: 'qtdeProjetado', align: 'right' },
        // { text: 'Vr. Unit Projetado', value: 'valorUnitProjetado' },
        { text: 'Vr. Total Projetado (R$)', value: 'valorTotalProjetado', align: 'right' },
      ],
      carregamentosDoMesSubHeaders: [
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
      ],

      // Agenda do Dia
      agendaDoDiaHeaders: [
        { text: '', value: 'familia' },
        { text: 'Filial', value: 'producao' },
        { text: 'Status', value: 'status' },
        { text: 'Agendado (m²)', value: 'agendado', align: 'right' },
        { text: 'Carregado (m²)', value: 'carregado', align: 'right' },
        { text: 'Frete (R$)', value: 'valor_frete', align: 'right' },
        { text: 'Total (R$)', value: 'valor_total', align: 'right' },
      ],
      agendaDoDiaSubHeaders: [
        { text: '' },
        { text: '' },
      ],

      // Agenda do Dia por Variedade
      agendaDoDiaPorVariedadesHeaders: [
        { text: 'Variedade', value: 'variedade' },
        { text: 'Agendado (m²)', value: 'agendado', align: 'right' },
        { text: 'Carregado (m²)', value: 'carregado', align: 'right' },
      ],
      agendaDoDiaPorVariedadesSubHeaders: [
        { text: '' },
        { text: '' },
      ],

      // Carregamentos do Mês por Variedades
      carregamentosDoMesPorVariedadesHeaders: [
        { text: 'Variedade', value: 'variedade' },
        { text: 'Qtde (m²)', value: 'quantidade', align: 'right' },
        { text: 'Vr. Unit. (R$)', value: 'vrUnit', align: 'right' },
        { text: 'Vr. Total (R$)', value: 'vrTotal', align: 'right' },
      ],
      carregamentosDoMesPorVariedadesSubHeaders: [
        { text: '' },
        { text: '' },
        { text: '' },
      ],
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'situacaoProducaoNome', 'situacaoProducaoClass']),
    dadosDoDiaItems () {
      let dadosDoDiaTemp = []

      // Soma valores do dia
      this.dashboardData
        .filter((item) => item.Tipo === '1')
        .reduce((res, value) => {
          if (!res[value.Familia]) {
            res[value.Familia] = {
              familia: value.Familia,
              agendado: 0,
              carregado: 0,
              vrUnit: 0,
              totalDoDia: 0,
            }

            dadosDoDiaTemp.push(res[value.Familia])
          }

          res[value.Familia].agendado += parseFloat(value.Agendado)
          res[value.Familia].carregado += parseFloat(value.Carregado)
          res[value.Familia].totalDoDia += parseFloat(value.VrItem)

          return res
        }, {})

      dadosDoDiaTemp = dadosDoDiaTemp.map((item) => {
        if (item.familia === 'SPRIGS') {
          item.agendado = this.$stringFormat.convertBSHToM2(item.agendado)
          item.carregado = this.$stringFormat.convertBSHToM2(item.carregado)
        }

        item.vrUnit = item.carregado !== 0 ? item.totalDoDia / item.carregado : 0
        return item
      })

      return dadosDoDiaTemp
    },

    acumuladosDoMesItems () {
      let acumuladosDoMesTemp = []

      // Soma valores do mes
      this.dashboardData
        .filter((item) => item.Tipo === '4')
        .reduce((res, value) => {
          if (!res[value.Familia]) {
            res[value.Familia] = {
              familia: value.Familia,
              qtdeMensal: 0,
              vrUnitMensal: 0,
              totalMensal: 0,
            }

            acumuladosDoMesTemp.push(res[value.Familia])
          }

          res[value.Familia].qtdeMensal += parseFloat(value.QtdeAcum)
          res[value.Familia].totalMensal += parseFloat(value.VrAcum)

          return res
        }, {})

      // Calcula valor unitário mensal
      acumuladosDoMesTemp = acumuladosDoMesTemp.map((item) => {
        if (item.familia === 'SPRIGS') {
          item.qtdeMensal = this.$stringFormat.convertBSHToM2(item.qtdeMensal)
        }

        item.vrUnitMensal = item.qtdeMensal !== 0 ? item.totalMensal / item.qtdeMensal : 0

        return item
      })

      return acumuladosDoMesTemp
    },

    retiradasDoAnoPorFamilia () {
      const retiradasDoAnoPorFamiliaTemp = []

      this.dashboardData
        .filter((item) => item.Tipo === '3')
        .reduce((res, value) => {
          if (!res[value.Familia]) {
            res[value.Familia] = {
              name: value.Familia,
              data: [],
            }

            retiradasDoAnoPorFamiliaTemp.push(res[value.Familia])
          }

          res[value.Familia].data.push(parseFloat(value.QtdeAcum))

          return res
        }, {})

      return retiradasDoAnoPorFamiliaTemp
    },

    retiradasDoAnoPorFamiliaChartOptions () {
      const that = this

      const chartOptions = {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ['#5F8FA3', '#9660A4', '#A47560', '#6EA460'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        title: {
          text: `Retiradas do Ano ${new Date(this.dataInput).getFullYear()} por família (m²)`,
          align: 'center',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 4,
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Mês',
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return that.$stringFormat.formatNumber(value, 0, 4)
            },
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
        },
        tooltip: {
          y: {
            formatter: (y) => {
              return this.$stringFormat.formatNumber(y, 0, 4)
            },
          },
        },
        noData: {
          text: this.chartNoData,
        },
      }

      // Pega somente os números dos meses (únicos) e transforma em nome dos meses
      chartOptions.xaxis.categories =
        this.$array.unique(
          this.$array.pluck(this.dashboardData.filter((item) => item.Tipo === '3'), 'Mes'),
        ).map((item) => this.$stringFormat.monthName(item - 1))

      return chartOptions
    },

    retiradasDoAno () {
      const retiradasDoAnoTemp = []

      retiradasDoAnoTemp.push(this.dashboardData
        .filter((item) => item.Tipo === '3')
        .reduce((res, value) => {
          const mes = parseInt(value.Mes) - 1

          const qtde = value.Familia === 'SPRIGS' ? this.$stringFormat.convertBSHToM2(parseFloat(value.QtdeAcum)) : parseFloat(value.QtdeAcum)

          if (!res.data[mes]) {
            res.data[mes] = qtde
          } else {
            res.data[mes] += qtde
          }

          return res
        }, {
          name: 'Total',
          data: [],
        }))

      return retiradasDoAnoTemp
    },

    retiradasDoAnoChartOptions () {
      const that = this
      const chartOptions = {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ['#5F8FA3'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        title: {
          text: `Retiradas do Ano ${new Date(this.dataInput).getFullYear()} (m²)`,
          align: 'center',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 4,
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Mês',
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return that.$stringFormat.formatNumber(value, 0, 4)
            },
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
        },
        tooltip: {
          y: {
            formatter: (y) => {
              return this.$stringFormat.formatNumber(y, 0, 4)
            },
          },
        },
        noData: {
          text: this.chartNoData,
        },

      }

      // Pega somente os números dos meses (únicos) e transforma em nome dos meses
      chartOptions.xaxis.categories =
        this.$array.unique(
          this.$array.pluck(this.dashboardData.filter((item) => item.Tipo === '3'), 'Mes'),
        ).map((item) => this.$stringFormat.monthName(item - 1))

      return chartOptions
    },

    retiradasDoMesPorFamilia () {
      const retiradasDoMesPorFamiliaTemp = []

      this.dashboardData
        .filter((item) => item.Tipo === '4')
        .reduce((res, value) => {
          if (!res[value.Familia]) {
            res[value.Familia] = {
              name: value.Familia,
              data: [],
            }

            retiradasDoMesPorFamiliaTemp.push(res[value.Familia])
          }

          res[value.Familia].data.push(parseFloat(value.QtdeAcum))

          return res
        }, {})

      return retiradasDoMesPorFamiliaTemp
    },

    retiradasDoMesPorFamiliaChartOptions () {
      const that = this
      const chartOptions = {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ['#5F8FA3', '#9660A4', '#A47560', '#6EA460'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        title: {
          text: `Retiradas de ${that.$stringFormat.monthName(new Date(this.dataInput).getUTCMonth())} ${new Date(this.dataInput).getFullYear()} (m²)`,
          align: 'center',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 4,
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Dia',
          },
          labels: {
            show: true,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return that.$stringFormat.formatNumber(value, 0, 4)
            },
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
        },
        tooltip: {
          x: {
            formatter: (x) => {
              return 'Dia ' + x
            },
          },
          y: {
            formatter: (y) => {
              return this.$stringFormat.formatNumber(y, 0, 4)
            },
          },
        },
        noData: {
          text: this.chartNoData,
        },
      }

      // Pega os dias exatos
      chartOptions.xaxis.categories =
        this.$array.unique(
          this.$array.pluck(this.dashboardData.filter((item) => item.Tipo === '4'), 'Dia'),
        )

      return chartOptions
    },

    retiradasDoMes () {
      const retiradasDoAnoTemp = []

      retiradasDoAnoTemp.push(this.dashboardData
        .filter((item) => item.Tipo === '4')
        .reduce((res, value) => {
          const dia = parseInt(value.Dia) - 1

          const qtde = value.Familia === 'SPRIGS' ? this.$stringFormat.convertBSHToM2(parseFloat(value.QtdeAcum)) : parseFloat(value.QtdeAcum)

          if (!res.data[dia]) {
            res.data[dia] = qtde
          } else {
            res.data[dia] += qtde
          }

          return res
        }, {
          name: 'Total',
          data: [],
        }))

      return retiradasDoAnoTemp
    },

    retiradasDoMesChartOptions () {
      const that = this
      const chartOptions = {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ['#5F8FA3', '#9660A4', '#A47560', '#6EA460'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        title: {
          text: `Retiradas de ${that.$stringFormat.monthName(new Date(this.dataInput).getUTCMonth())} ${new Date(this.dataInput).getFullYear()} (m²)`,
          align: 'center',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 4,
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Dia',
          },
          labels: {
            show: true,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return that.$stringFormat.formatNumber(value, 0, 4)
            },
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
        },
        tooltip: {
          x: {
            formatter: (x) => {
              return 'Dia ' + x
            },
          },
          y: {
            formatter: (y) => {
              return this.$stringFormat.formatNumber(y, 0, 4)
            },
          },
        },
        noData: {
          text: this.chartNoData,
        },
      }

      // Pega os dias exatos
      chartOptions.xaxis.categories = this.$array.unique(
        this.$array.pluck(this.dashboardData.filter((item) => item.Tipo === '4'), 'Dia'),
      )

      return chartOptions
    },

    retiradasDoMesPorSemana () {
      const retiradasDoMesSemanaTemp = []

      retiradasDoMesSemanaTemp.push(this.dashboardData
        .filter((item) => item.Tipo === '4')
        .reduce((res, value) => {
          const qtde = value.Familia === 'SPRIGS' ? this.$stringFormat.convertBSHToM2(parseFloat(value.QtdeAcum)) : parseFloat(value.QtdeAcum)
          const semanaDoAno = this.$date.weekOfYear(`${value.Mes}-${value.Dia}-${value.Ano}`)

          if (!res.data[semanaDoAno]) {
            res.data[semanaDoAno] = qtde
          } else {
            res.data[semanaDoAno] += qtde
          }

          return res
        }, {
          name: 'Total',
          data: [],
        }))

      retiradasDoMesSemanaTemp[0].data = retiradasDoMesSemanaTemp[0].data.filter(() => true)

      return retiradasDoMesSemanaTemp
    },

    retiradasDoMesPorSemanaChartOptions () {
      const that = this
      const categories = []
      const totalWeeks = this.retiradasDoMesPorSemana[0].data.length || 5

      // Altera quantidade de colunas/semanas/categorias
      for (let i = 1; i <= totalWeeks; i++) {
        categories.push(`Semana ${i}`)
      }

      return {
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
            style: {
              colors: ['#5F8FA3'],
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return that.$stringFormat.formatNumber(value, 0, 2)
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
        },
        fill: {
          colors: ['#5F8FA3'],
        },
        xaxis: {
          categories,
          position: 'top',
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return that.$stringFormat.formatNumber(value, 0, 2)
            },
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value) => {
              return that.$stringFormat.formatNumber(value, 0, 2)
            },
          },
        },
        title: {
          text: `Retiradas de ${that.$stringFormat.monthName(new Date(this.dataInput).getUTCMonth())} ${new Date(this.dataInput).getFullYear()} (m²)`,
          align: 'center',
        },
        noData: {
          text: this.chartNoData,
        },
      }
    },

    carregamentosDoMesItems () {
      const carregamentosDoMesTemp = []

      this.dashboardData
        .filter((item) => item.Tipo === '1')
        .map((item) => {
          let qtdeAcum = 0
          let valorUnitRealizado = 0
          let qtdeProj = 0
          let valorUnitProjetado = 0

          if (parseFloat(item.QtdeAcum) !== 0) {
            qtdeAcum = item.Familia === 'SPRIGS' ? this.$stringFormat.convertBSHToM2(parseFloat(item.QtdeAcum)) : parseFloat(item.QtdeAcum)
            valorUnitRealizado = parseFloat(item.VrAcum) / qtdeAcum
          }

          if (parseFloat(item.QtdeProj) !== 0) {
            qtdeProj = item.Familia === 'SPRIGS' ? this.$stringFormat.convertBSHToM2(parseFloat(item.QtdeProj)) : parseFloat(item.QtdeProj)
            valorUnitProjetado = parseFloat(item.VrProj) / item.QtdeProj
          }

          carregamentosDoMesTemp.push({
            empresa_id: item.CodEmp,
            producao_id: item.CodFil,
            familia: item.Familia,
            producao: item.Criterio,
            diasCarregados: parseInt(item.Dias),
            qtdeRealizado: qtdeAcum,
            valorUnitRealizado: valorUnitRealizado,
            valorRealizado: parseFloat(item.VrAcum),
            diasUteis: parseInt(item.DiasUteis),
            qtdeProjetado: qtdeProj,
            valorUnitProjetado: valorUnitProjetado,
            valorTotalProjetado: parseFloat(item.VrProj),
          })
        })

      return carregamentosDoMesTemp
    },

    agendaDoDiaItems () {
      const agendaDoDiaTemp = []

      const faturamentoData = this.faturamentoData

      this.dashboardData
        .filter((item) => item.Tipo === '1')
        .map((item) => {
          if (parseFloat(item.Agendado) !== 0 || parseFloat(item.Carregado) !== 0) {
            var faturamento = _.filter(faturamentoData, o => o.CodEmp == item.CodEmp && o.CodFil == item.CodFil && o.Familia == item.Familia)

            agendaDoDiaTemp.push({
              familia: item.Familia,
              empresa_id: item.CodEmp,
              producao_id: item.CodFil,
              producao: item.Criterio,
              agendado: item.Familia === 'SPRIGS' ? this.$stringFormat.convertBSHToM2(item.Agendado) : parseFloat(item.Agendado),
              carregado: item.Familia === 'SPRIGS' ? this.$stringFormat.convertBSHToM2(item.Carregado) : parseFloat(item.Carregado),
              valor_frete: parseFloat(faturamento[0]?.VlrFre),
              valor_total: parseFloat(faturamento[0]?.VlrPed),
            })
          }
        })

      return agendaDoDiaTemp
    },

    agendaDoDiaPorVariedadesItems () {
      const agendaDoDiaPorVariedadesTemp = []

      this.dashboardData
        .filter((item) => item.Tipo === '2')
        .map((item) => {
          if (parseFloat(item.Agendado) !== 0 || parseFloat(item.Carregado) !== 0) {
            agendaDoDiaPorVariedadesTemp.push({
              familia: item.Familia,
              variedade: item.Criterio,
              agendado: item.Familia === 'SPRIGS' ? this.$stringFormat.convertBSHToM2(item.Agendado) : parseFloat(item.Agendado),
              carregado: item.Familia === 'SPRIGS' ? this.$stringFormat.convertBSHToM2(item.Carregado) : parseFloat(item.Carregado),
            })
          }
        })

      return agendaDoDiaPorVariedadesTemp
    },

    carregamentosDoMesPorVariedadesItems () {
      const carregamentosDoMesPorVariedadesTemp = []

      this.dashboardData
        .filter((item) => item.Tipo === '2')
        .map((item) => {
          const QtdeAcum = item.Familia === 'SPRIGS' ? this.$stringFormat.convertBSHToM2(item.QtdeAcum) : parseFloat(item.QtdeAcum)

          if (parseFloat(item.QtdeAcum) !== 0 || parseFloat(item.VrAcum) !== 0) {
            carregamentosDoMesPorVariedadesTemp.push({
              familia: item.Familia,
              variedade: item.Criterio,
              quantidade: QtdeAcum,
              vrUnit: parseFloat(QtdeAcum) !== 0 ? parseFloat(item.VrAcum) / parseFloat(QtdeAcum) : 0,
              vrTotal: parseFloat(item.VrAcum),
            })
          }
        })

      return carregamentosDoMesPorVariedadesTemp
    },
  },
  watch: {
    svisSelected: function (value) {
      this.pendingSearch = true
    },

    producoesSelected: function (value) {
      this.pendingSearch = true
    },

    dataInput: function (value) {
      this.pendingSearch = true
    },
  },
  mounted () {
    this.dashboardData = []
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: 'BI'
    }, {
      title: 'Carregamentos'
    }])

    this.$store.dispatch(UPDATE_SITUACAO_PRODUCAO, { data: this.dataInput })

    this.loadDashboardData()
  },
  methods: {
    seachFilter () {
      this.dashboardData = []
      this.$store.dispatch(CLOSE_FILTER)
      this.$store.dispatch(UPDATE_SITUACAO_PRODUCAO, { data: this.dataInput })
      this.loadDashboardData()
    },
    todosFiltro () {
      return this.svisSelected.map((svi) => {
        return {
          id: 0,
          empresa: 0,
          svi: svi.id ?? 0,
        }
      })
    },
    producoesFiltro () {
      if (this.producoesSelected[0] === '' || this.producoesSelected[0].producao_id === '') {
        return this.todosFiltro()
      }

      return this.producoesSelected.map(function (producao) {
        return {
          id: producao.producao_id ? producao.producao_id : 0,
          empresa: producao.empresa_id ? producao.empresa_id : 0,
          svi: producao.svi ?? 0,
        }
      })
    },

    async loadDashboardData () {
      this.loading = true
      this.pendingSearch = false

      const params = {
        producoes: this.producoesFiltro(),
        data: this.dataInput,
      }

      const [dashboardData, faturamentoData] = await Promise.all([
        ApiService.query('/dashboard', { params }),
        ApiService.query('/dashboard/producao-faturamento', { params })
      ])

      this.dashboardData = window.dados = dashboardData.data.data
      this.faturamentoData = faturamentoData.data

      this.loading = false
    },

    toggleChartRetiradasAno (groupType) {
      this.groupTypeChartRetiradasAno = groupType
    },

    toggleChartRetiradasMes (groupType) {
      this.groupTypeChartRetiradasMes = groupType
    },

    async getProducoesSelectOptions (sviId) {
      this.producoesSelectOptions = this.$producoes.listProducoesBySvi(sviId)
    },

    totalSomadoDadosDoDia () {
      const sum = this.dadosDoDiaItems.reduce((res, value) => {
        res.agendado += parseFloat(value.agendado)
        res.carregado += parseFloat(value.carregado)
        res.totalDoDia += parseFloat(value.totalDoDia)

        return res
      }, {
        agendado: 0,
        carregado: 0,
        vrUnit: 0,
        totalDoDia: 0,
      })

      sum.vrUnit = this.$stringFormat.formatNumber(sum.totalDoDia !== 0 ? sum.totalDoDia / sum.carregado : 0)
      sum.agendado = this.$stringFormat.formatNumber(sum.agendado, 0, 4)
      sum.carregado = this.$stringFormat.formatNumber(sum.carregado, 0, 4)
      sum.totalDoDia = this.$stringFormat.formatNumber(sum.totalDoDia, 0, 0)

      return Object.keys(sum).map((a) => sum[a])
    },

    totalSomadoAcumuladoDoMes () {
      const sum = this.acumuladosDoMesItems.reduce((res, value) => {
        res.qtdeMensal += parseFloat(value.qtdeMensal)
        res.vrUnitMensal += parseFloat(value.vrUnitMensal)
        res.totalMensal += parseFloat(value.totalMensal)

        return res
      }, {
        qtdeMensal: 0,
        vrUnitMensal: 0,
        totalMensal: 0,
      })

      sum.vrUnitMensal = this.$stringFormat.formatNumber(sum.qtdeMensal !== 0 ? (sum.totalMensal / sum.qtdeMensal) : 0)
      sum.qtdeMensal = this.$stringFormat.formatNumber(sum.qtdeMensal, 0, 4)
      sum.totalMensal = this.$stringFormat.formatNumber(sum.totalMensal, 0, 0)

      return Object.keys(sum).map((a) => sum[a])
    },

    totalSomadoCarregamentosDoMes (familia = null) {
      const sum = this.carregamentosDoMesItems.reduce((res, value) => {
        if (familia === value.familia || familia === 'todos') {
          // res.diasCarregados = 0
          res.qtdeRealizado += parseFloat(value.qtdeRealizado)
          // res.valorUnitRealizado += parseFloat(value.valorUnitRealizado)
          res.valorRealizado += parseFloat(value.valorRealizado)
          // res.diasUteis = ''
          res.qtdeProjetado += parseFloat(value.qtdeProjetado)
          // res.valorUnitProjetado += parseFloat(value.valorUnitProjetado)
          res.valorTotalProjetado += parseFloat(value.valorTotalProjetado)
        }

        return res
      }, {
        // diasCarregados: '',
        qtdeRealizado: 0,
        valorUnitRealizado: 0,
        valorRealizado: 0,
        // diasUteis: '',
        qtdeProjetado: 0,
        // valorUnitProjetado: 0,
        valorTotalProjetado: 0,
      })

      sum.qtdeProjetado = this.$stringFormat.formatNumber(sum.qtdeProjetado, 0, 0)
      sum.valorUnitRealizado = this.$stringFormat.formatNumber(sum.qtdeRealizado !== 0 ? (sum.valorRealizado / sum.qtdeRealizado) : 0)
      sum.qtdeRealizado = this.$stringFormat.formatNumber(sum.qtdeRealizado, 0, 0)
      sum.valorRealizado = this.$stringFormat.formatNumber(sum.valorRealizado, 0, 0)
      // sum.valorUnitProjetado = this.$stringFormat.formatNumber(sum.valorUnitProjetado)
      sum.valorTotalProjetado = this.$stringFormat.formatNumber(sum.valorTotalProjetado, 0, 0)

      return Object.keys(sum).map((a) => sum[a])
    },

    totalSomadoAgendaDoDia (familia = null) {
      const sum = this.agendaDoDiaItems.reduce((res, value) => {
        if (familia === value.familia || familia === 'todos') {
          res.agendado += parseFloat(value.agendado)
          res.carregado += parseFloat(value.carregado)
          res.valor_frete += parseFloat(value.valor_frete)
          res.valor_total += parseFloat(value.valor_total)
        }

        return res
      }, {
        agendado: 0,
        carregado: 0,
        valor_frete: 0,
        valor_total: 0,
      })

      sum.agendado = this.$stringFormat.formatNumber(sum.agendado, 0, 0)
      sum.carregado = this.$stringFormat.formatNumber(sum.carregado, 0, 0)
      sum.valor_frete = this.$stringFormat.formatNumber(sum.valor_frete, 0, 0)
      sum.valor_total = this.$stringFormat.formatNumber(sum.valor_total, 0, 0)

      return Object.keys(sum).map((a) => sum[a])
    },

    totalSomadoAgendaDoDiaPorVariedades (familia = null) {
      const sum = this.agendaDoDiaPorVariedadesItems.reduce((res, value) => {
        if (familia === value.familia || familia === 'todos') {
          res.agendado += parseFloat(value.agendado)
          res.carregado += parseFloat(value.carregado)
        }

        return res
      }, {
        agendado: 0,
        carregado: 0,
      })

      sum.agendado = this.$stringFormat.formatNumber(sum.agendado, 0, 4)
      sum.carregado = this.$stringFormat.formatNumber(sum.carregado, 0, 4)

      return Object.keys(sum).map((a) => sum[a])
    },

    totalSomadoCarregamentosDoMesPorVariedadesItems (familia = null) {
      const sum = this.carregamentosDoMesPorVariedadesItems.reduce((res, value) => {
        if (familia === value.familia || familia === 'todos') {
          res.quantidade += parseFloat(value.quantidade)
          res.vrTotal += parseFloat(value.vrTotal)
        }

        return res
      }, {
        quantidade: 0,
        vrUnit: 0,
        vrTotal: 0,
      })

      sum.vrUnit = this.$stringFormat.formatNumber(sum.quantidade !== 0 ? (sum.vrTotal / sum.quantidade) : 0)
      sum.quantidade = this.$stringFormat.formatNumber(sum.quantidade, 0, 4)
      sum.vrTotal = this.$stringFormat.formatNumber(sum.vrTotal, 0, 0)

      return Object.keys(sum).map((a) => sum[a])
    },
  },
}
</script>
